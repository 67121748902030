<template>
  <div class="container-fluid">
    <div v-if="!reservation" class="my-3 text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div v-else>
      <h2 class="my-3">{{ reservation.parkName }}</h2>
      <p class="mt-3">
        <b>
          You may change your Personal Information, Occupancy Information and/or
          Equipment Details below. Additional fees may apply for some changes.
          Calculated payment or refund details will be details will be displayed
          for confirmation prior to completing the change.
        </b>
      </p>
      <div class="row">
        <div class="col-8">
          <ValidationObserver ref="editReservationForm">
            <form @submit.prevent="onSubmit">
              <FormErrorAlert
                v-if="errors.length > 0"
                :errors="errors"
                :formRef="this.$refs.editReservationForm"
              />
              <div class="card">
                <div class="card-header">
                  <div class="card-title mb-0">Primary Occupant</div>
                </div>
                <div class="card-body">
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        rules="required|customerName|max:30"
                        name="First Name"
                        id="firstName"
                        placeholder="Enter your first name"
                        v-model="reservation.primaryOccupant.firstName"
                      />
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        rules="required|customerName|max:30"
                        name="Last Name"
                        id="lastName"
                        placeholder="Enter your last name"
                        v-model="reservation.primaryOccupant.lastName"
                      />
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        rules="required|address|max:70"
                        name="Address 1"
                        id="street1"
                        placeholder="Enter your street address"
                        v-model="reservation.primaryOccupant.street1"
                      />
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        rules="address|max:70"
                        name="Address 2"
                        id="street2"
                        placeholder="Enter your street address, cont."
                        v-model="reservation.primaryOccupant.street2"
                      />
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        rules="required|city|max:58"
                        name="City"
                        id="city"
                        placeholder="Enter your city"
                        v-model="reservation.primaryOccupant.city"
                      />
                    </div>
                  </div>
                  <div
                    class="form-row justify-content-center"
                    v-if="isForeignAddress"
                  >
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        class="mb-2"
                        rules="required|city|max:20"
                        name="State"
                        id="state"
                        placeholder="Enter your state"
                        v-model="reservation.primaryOccupant.state"
                      />
                    </div>
                  </div>
                  <div class="form-row justify-content-center" v-else>
                    <div class="col col-12 form-group text-left">
                      <ValidationProvider
                        rules="required"
                        name="State"
                        v-slot="{ errors, ariaInput, ariaMsg }"
                      >
                        <label
                          @click="$refs.select.focus()"
                          :class="{ error: errors[0] }"
                          for="state"
                        >
                          State
                          <span class="error">*</span>
                        </label>
                        <select
                          class="form-control form-control-lg"
                          id="state"
                          v-model="reservation.primaryOccupant.state"
                          v-bind="ariaInput"
                        >
                          <option
                            v-for="(state, index) in states"
                            :key="index"
                            :value="state.abbreviation"
                          >
                            {{ state.name }}
                          </option>
                        </select>
                        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                          <li
                            v-for="(error, index) in errors"
                            :key="index"
                            class="error"
                            v-bind="ariaMsg"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        :rules="
                          isForeignAddress
                            ? 'required'
                            : 'required|min:5|zipCode'
                        "
                        name="Zip Code"
                        id="zipCode"
                        placeholder="Enter your zip code"
                        v-model="reservation.primaryOccupant.zipcode"
                        :mask="isForeignAddress ? '' : '#####-####'"
                      />
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        type="email"
                        rules="required|email|max:256"
                        name="Email"
                        id="email"
                        placeholder="Enter your email address"
                        v-model="reservation.primaryOccupant.email"
                      />
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        rules="required|maskNumMin:10"
                        name="Phone"
                        id="phone"
                        placeholder="Enter your phone number"
                        v-model="reservation.primaryOccupant.mobilePhone"
                        mask="(###) ###-####"
                      />
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        rules="maskNumMin:10"
                        name="Alternate Phone"
                        id="alternatePhone"
                        placeholder="Enter your alternate phone number"
                        v-model="reservation.primaryOccupant.homePhone"
                        mask="(###) ###-####"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title mb-0">Occupancy Information</div>
                </div>
                <div class="card-body">
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <ValidationProvider
                        rules="required"
                        name="Number Of People"
                        v-slot="{ errors, ariaInput, ariaMsg }"
                      >
                        <label
                          @click="$refs.select.focus()"
                          :class="{ error: errors[0] }"
                          for="numberOfPeople"
                        >
                          Number Of People
                          <span class="error">*</span>
                        </label>
                        <select
                          class="form-control form-control-lg"
                          id="numberOfPeople"
                          v-model="reservation.numberOfAdults"
                          v-bind="ariaInput"
                        >
                          <option
                            v-for="(num, index) in peopleNumbers"
                            :key="index"
                            :value="num"
                          >
                            {{ num }}
                          </option>
                        </select>
                        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                          <li
                            v-for="(error, index) in errors"
                            :key="index"
                            class="error"
                            v-bind="ariaMsg"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <ValidationProvider
                        rules="required"
                        name="Number Of Vehicles"
                        v-slot="{ errors, ariaInput, ariaMsg }"
                      >
                        <label
                          @click="$refs.select.focus()"
                          :class="{ error: errors[0] }"
                          for="numberOfVehicles"
                        >
                          Number Of Vehicles
                          <span class="error">*</span>
                        </label>
                        <select
                          class="form-control form-control-lg"
                          id="numberOfVehicles"
                          v-model="reservation.numberOfVehicles"
                          v-bind="ariaInput"
                        >
                          <option
                            v-for="(num, index) in vehicleNumbers"
                            :key="index"
                            :value="num"
                          >
                            {{ num }}
                          </option>
                        </select>
                        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                          <li
                            v-for="(error, index) in errors"
                            :key="index"
                            class="error"
                            v-bind="ariaMsg"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div
                    v-for="discount in discountClasses"
                    :key="discount.id"
                    class="form-row justify-content-center"
                  >
                    <div class="col col-12 form-group text-left">
                      <label>
                        <input
                          type="checkbox"
                          :value="discount.id"
                          :id="`discount_class_${discount.id}`"
                          v-model="reservation.discounts"
                        />
                        {{ discount.name }}
                      </label>
                      <p v-html="discount.description"></p>
                    </div>
                  </div>
                  <p class="text-muted m-0">
                    Special rate/discount may be eligible for pass holders,
                    special group and membership holders. Proof of
                    age/membership may be required at the time of check in.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title mb-0">Equipment Information</div>
                </div>
                <div class="card-body">
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <ValidationProvider
                        rules="required"
                        name="Equipment Type"
                        v-slot="{ errors, ariaInput, ariaMsg }"
                      >
                        <label
                          @click="$refs.select.focus()"
                          :class="{ error: errors[0] }"
                          for="equipmentType"
                        >
                          Equipment Type
                          <span class="error">*</span>
                        </label>
                        <select
                          class="form-control form-control-lg"
                          id="equipmentType"
                          v-model="reservation.equipmentType"
                          v-bind="ariaInput"
                        >
                          <option
                            v-for="spotEquipmentType in spotEquipmentTypes"
                            :key="spotEquipmentType.equipmentType.id"
                            :value="spotEquipmentType.equipmentType.name"
                          >
                            {{ spotEquipmentType.equipmentType.name }}
                          </option>
                        </select>
                        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                          <li
                            v-for="(error, index) in errors"
                            :key="index"
                            class="error"
                            v-bind="ariaMsg"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        :rules="
                          `${
                            this.reservation.equipmentType !== 'None'
                              ? 'required'
                              : ''
                          }|numeric|${
                            maxVehicleLength
                              ? 'between:0,' + maxVehicleLength
                              : ''
                          }`
                        "
                        name="Equipment Length (ft)"
                        id="equipmentLength"
                        placeholder="Enter length of equipment"
                        v-model="reservation.equipmentLength"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p>
                <span style="color: red">*</span> Indicates a required field
              </p>
              <button
                type="submit"
                class="btn btn-lg btn-primary mr-2 mb-3 float-left"
              >
                Continue Edit
              </button>
              <button
                type="button"
                @click="cancel(false)"
                class="btn btn-lg mr-2 btn-secondary mb-3 float-left"
              >
                Back
              </button>
              <button
                type="button"
                @click="cancel(true)"
                class="btn btn-cancel btn-lg mr-2 mb-3 float-left"
              >
                Abandon Reservation Changes
              </button>
            </form>
          </ValidationObserver>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="card-header">
              <div class="card-title mb-0">Original Reservation</div>
            </div>
            <div class="card-body">
              <div v-if="!oldSpotDetails" class="my-3 text-center">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
              <div v-else>
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Park</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    <div class="info-label">{{ reservation.parkName }}</div>
                  </div>
                </div>
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Site</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    <div class="info-label">
                      {{ oldSpotDetails ? oldSpotDetails.name : "" }}
                    </div>
                  </div>
                </div>
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Loop</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    <div class="info-label">
                      {{ oldSpotDetails ? oldSpotDetails.loop.name : "" }}
                    </div>
                  </div>
                </div>
                <div
                  class="form-row justify-content-center"
                  v-if="!reservation.isDayUse"
                >
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Number of Nights</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    <div class="info-label">{{ previousNumberOfNights }}</div>
                  </div>
                </div>
                <hr class="mt-0" />
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Check In</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    {{ reservation.oldStartDate | formatDate("MM-DD-YYYY") }}
                    {{ oldSpotDetails.checkInTime | formatTime }}
                  </div>
                </div>
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Check Out</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    {{ reservation.oldEndDate | formatDate("MM-DD-YYYY") }}
                    {{ oldSpotDetails.checkOutTime | formatTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-title mb-0">New Reservation</div>
            </div>
            <div class="card-body">
              <div v-if="!newSpotDetails" class="my-3 text-center">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
              <div v-else>
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Park</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    <div class="info-label">{{ reservation.parkName }}</div>
                  </div>
                </div>
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Site</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    <div class="info-label">
                      {{ newSpotDetails.name ? newSpotDetails.name : "" }}
                    </div>
                  </div>
                </div>
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Loop</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    <div class="info-label">
                      {{
                        newSpotDetails.loop.name ? newSpotDetails.loop.name : ""
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="form-row justify-content-center"
                  v-if="!reservation.isDayUse"
                >
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Number of Nights</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    <div class="info-label">{{ newNumberOfNights }}</div>
                  </div>
                </div>
                <hr class="mt-0" />
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Check In</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    {{ reservation.startDate | formatDate("MM-DD-YYYY") }}
                    {{ newSpotDetails.checkInTime | formatTime }}
                  </div>
                </div>
                <div class="form-row justify-content-center">
                  <div class="col col-6 form-group text-left">
                    <div class="info-label">Check Out</div>
                  </div>
                  <div class="col col-6 form-group text-right">
                    {{ reservation.endDate | formatDate("MM-DD-YYYY") }}
                    {{ newSpotDetails.checkOutTime | formatTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Alert
            v-if="
              newSpotDetails &&
                newSpotDetails.product.productClassification
                  .applyMessageToWebsite
            "
            :alert="{
              htmlMessage: newSpotDetails.product.productClassification.message,
              type: 'alert-primary'
            }"
            class="mb-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";
import states from "@/store/modules/states.js";
import LocationService from "@/services/LocationService.js";
import SpotReservationService from "@/services/SpotReservationService.js";
import TenantService from "@/services/TenantService.js";
import PricingService from "@/services/PricingService.js";
import moment from "moment";
import Alert from "@/components/alert/Alert.vue";

export default {
  name: "CustomerProfileReservationEditOccupant",
  title: "Customer Profile Reservation Edit Occupant",
  components: {
    ValidationObserver,
    ValidationProvider,
    TextInput,
    FormErrorAlert,
    Alert
  },
  data() {
    return {
      errors: {},
      reservation: null,
      oldSpotDetails: null,
      newSpotDetails: null,
      discountClasses: []
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    cancel(isCancel) {
      //Release lock
      const spotReservationService = new SpotReservationService(this.tenantId);
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      //Ensure we have a lock
      if (claimData.lockCode) {
        spotReservationService
          .releaseSpotClaim(claimData.spotId, claimData.lockCode)
          .then(response => {
            if (response.statusCode === "Success") {
              this.clearReservationAndReturn(isCancel);
            }
          });
      } else {
        this.clearReservationAndReturn(isCancel);
      }
    },
    clearReservationAndReturn(isCancel) {
      //Clear reservation changes
      const externalUserId = this.externalUserId;

      //Route to reservation list
      if (isCancel) {
        this.$store.dispatch("transaction/clear");
        this.$router
          .push("/customer-profile/" + externalUserId + "#reservations")
          .catch(() => {});
      } else {
        this.$store.dispatch("transaction/clearReservation");
        this.$router
          .push(
            `/reservation-edit?r=${this.reservation.reservationId}&c=${this.reservation.customerId}&l=${this.reservation.locationId}&e=${externalUserId}`
          )
          .catch(() => {});
      }
    },
    async initialize() {
      const reservation = this.$store.getters["transaction/reservation"];
      reservation.primaryOccupant.state = reservation.primaryOccupant.state.trim();
      reservation.primaryOccupant.zipcode = reservation.primaryOccupant.zipcode.trim();
      reservation.primaryOccupant.mobilePhone = reservation.primaryOccupant
        .mobilePhone
        ? reservation.primaryOccupant.mobilePhone.trim()
        : "";
      reservation.primaryOccupant.homePhone = reservation.primaryOccupant
        .homePhone
        ? reservation.primaryOccupant.homePhone.trim()
        : "";
      reservation.oldNumberOfAdults = reservation.numberOfAdults;
      this.reservation = reservation;
      await this.loadSpots();
      await this.getDiscounts();
      if (this.newSpotDetails && this.newSpotDetails?.spotEquipmentTypes) {
        var equipmentTypes = this.newSpotDetails.spotEquipmentTypes.map(
          x => x.equipmentType.name
        );
        if (!equipmentTypes.includes(this.reservation.equipmentType)) {
          this.reservation.equipmentType = null;
        }
      }
      this.$store.commit("auth/setLoading", false);
    },
    async loadSpots() {
      const locationService = new LocationService(this.tenantId);
      if (!this.newSpotId) {
        locationService
          .getSpotDetails(this.reservation.oldSpotId)
          .then(response => {
            this.oldSpotDetails = response.spotDetails;
            this.newSpotDetails = response.spotDetails;
          });
      } else {
        locationService
          .getSpotDetails(this.reservation.oldSpotId)
          .then(response => {
            this.oldSpotDetails = response.spotDetails;
          });
        locationService.getSpotDetails(this.newSpotId).then(response => {
          this.newSpotDetails = response.spotDetails;
        });
      }
    },
    onSubmit() {
      this.$refs.editReservationForm.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.editReservationForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.editReservationForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            this.reservation.phone = this.reservation.primaryOccupant.mobilePhone;
            this.reservation.alternatePhone = this.reservation.primaryOccupant.homePhone;
            this.reservation.zipCode = this.reservation.primaryOccupant.zipcode;
            this.$store.dispatch(
              "transaction/setReservation",
              this.reservation
            );
            this.$router.push("/reservation-edit-review");
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "public"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    async getFees() {
      const pricingService = new PricingService(this.tenantId);
      const feeResponse = await pricingService.getFees(
        {
          spotId: +this.reservation.spotId,
          oldStartDate: this.reservation.oldStartDate,
          oldEndDate: this.reservation.oldEndDate,
          oldSpotId: this.reservation.oldSpotId,
          startDate: this.reservation.startDate,
          endDate: this.reservation.endDate,
          numberOfAdults: this.reservation.numberOfAdults,
          numberOfVehicles: this.reservation.numberOfVehicles,
          discounts: this.reservation.discounts,
          customerId: this.reservation.customerId,
          salesChannel: 1
        },
        2
      );
      return feeResponse.data;
    },
    async getDiscounts() {
      const tenantService = new TenantService(this.tenantId);
      const response = await tenantService.getDiscountClasses();
      this.discountClasses = response.data;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    externalUserId() {
      return this.$store.getters["auth/externalUserId"];
    },
    newSpotId() {
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      if (claimData == null) {
        return null;
      }
      return claimData.spotId;
    },
    maxPeople() {
      if (!this.newSpotDetails) return null;
      const attr = this.newSpotDetails.attributes.find(
        x => x.globalAttributeId === 133
      );
      return attr ? attr.value : null;
    },
    maxVehicles() {
      if (!this.newSpotDetails) return null;
      const attr = this.newSpotDetails.attributes.find(
        x => x.globalAttributeId === 134
      );
      return attr ? attr.value : null;
    },
    maxVehicleLength() {
      if (!this.newSpotDetails) return null;
      const attr = this.newSpotDetails.attributes.find(
        x => x.globalAttributeId === 135
      );
      return attr ? attr.value : null;
    },
    spotEquipmentTypes() {
      if (!this.newSpotDetails) return [];
      return this.newSpotDetails.spotEquipmentTypes;
    },
    states() {
      return states;
    },
    previousNumberOfNights() {
      return moment(this.reservation.oldEndDate, "MM/DD/YYYY").diff(
        moment(this.reservation.oldStartDate, "MM/DD/YYYY"),
        "days"
      );
    },
    newNumberOfNights() {
      return moment(this.reservation.endDate, "MM/DD/YYYY").diff(
        moment(this.reservation.startDate, "MM/DD/YYYY"),
        "days"
      );
    },
    peopleNumbers() {
      const peopleNumbers = [];
      for (let i = 1; i <= this.maxPeople; i++) {
        peopleNumbers.push(i);
      }
      return peopleNumbers;
    },
    vehicleNumbers() {
      const vehicleNumbers = [];
      for (let i = 0; i <= this.maxVehicles; i++) {
        vehicleNumbers.push(i);
      }
      return vehicleNumbers;
    },
    isForeignAddress() {
      return this.reservation.primaryOccupant.country !== "US";
    }
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
.summary {
  min-height: 510px;
}
@media (min-width: 1000px) {
  .container-fluid {
    width: 90%;
  }
}
p > b {
  font-size: large;
}
</style>
